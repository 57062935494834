<template>
  <div>
    <b-row
      ><b-col>
        <b-nav card-header pills class="m-0 mt-1">
          <b-nav-item
            :to="{ name: $route.meta.declinedManualRoute }"
            exact
            exact-active-class="sub-tab-nav"
            :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
            :active="$route.matched[3].name ==  $route.meta.declinedManualRoute "
          >
            Manual 
          </b-nav-item>
          <b-nav-item
            :to="{ name: $route.meta.declinedOthersRoute }"
            exact
            exact-active-class="sub-tab-nav"
            :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
            :active="$route.matched[3].name ==  $route.meta.declinedOthersRoute "

          >
            Others 
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>
    <b-card class="border-top-info border-3 border-table-radius px-0">
    <router-view :key="$route.name" />
  </b-card>
  </div>
</template>
<script>
export default {
};
</script>